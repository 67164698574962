import merge from 'lodash/merge'

let appEnv
const hostname = window && window.location && window.location.hostname

export const API_GATEWAY_URL_PROD = 'https://api.target.com'
export const API_GATEWAY_URL_STG = 'https://stage-api.target.com'

const API_GATEWAY_URL_LOCAL = process.env.NODE_ENV === 'test' ? '' : API_GATEWAY_URL_STG

const GF_CLIENT_ID_PROD = 'greenfield_prod_im'
const GF_CLIENT_ID_STG = 'greenfield_npe_im'

const API_KEY_PROD = '5b95b166a0dbfc2be1e314f636f778107723e602'
const API_KEY_STG = '32b962618e15b3a1aab66f13def4309e6b9b02e0'
const API_KEY_PARTNERS_PROD = '2756c9136be90501489deaf283a89bac06cc85c8'
const API_KEY_PARTNERS_STG = '711d10991750c9381bcfd828bb67fcc87b05096f'

const AUTHORIZATION_URL_PROD = 'https://oauth.iam.target.com/auth/oauth/v2/authorize'
const AUTHORIZATION_URL_STG = 'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize'
const AUTHORIZATION_URL_PARTNERS_PROD = 'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/we2w/1'
const AUTHORIZATION_URL_PARTNERS_STG = 'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/we2w/1'

const LOGOUT_URL_PROD = `https://logonservices.iam.target.com/login/responses/logoff.html?target=${window.location.origin}/`
const LOGOUT_URL_STG = `https://logonservices.iam.perf.target.com/login/responses/logoff.html?target=${window.location.origin}/`
const LOGOUT_URL_PARTNERS_PROD = `https://oauth.iam.partnersonline.com/login/responses/logoff.html?target=${window.location.origin}/`
const LOGOUT_URL_PARTNERS_STG = `https://oauth.iam.perf.partnersonline.com/login/responses/logoff.html?target=${window.location.origin}/`

const DOCS_SITE_PROD = 'https://greenfielddocsite.target.com'
const DOCS_SITE_STG = 'https://greenfielddocsite-release.target.com'
const DOCS_SITE_PARTNERS_PROD = 'https://greenfielddocsite.partnersonline.com'
const DOCS_SITE_PARTNERS_STG = 'https://greenfielddocsite-release.partnersonline.com'

const ANALYTICS_API_KEY_PROD = '0ef4ca6577bc764985619262a2ae50ff412fa549'
const ANALYTICS_API_KEY_STG = 'ce21b92191a3990784f94e14ff07713628057642'

const commonConfig = {
  auth: {
    loginRedirect: `${window.location.origin}/`,
    responseType: 'token id_token',
    scope: ['openid profile'],
    storageType: 'localStorage',
    tokenType: 'Bearer',
  },
  froalaDecryptKey: '2345',
  agGridDecryptKey: '4582',
}

const envConfigs = {
  local: {
    auth: {
      authorizationUrl: AUTHORIZATION_URL_STG,
      logoutUrl: LOGOUT_URL_STG,
      clientId: GF_CLIENT_ID_STG,
    },
    gnfapi: {
      apikey: API_KEY_STG,
    },
    gatewayUrl: API_GATEWAY_URL_LOCAL,
    analyticsApiKey: ANALYTICS_API_KEY_STG,
    help: {
      url: DOCS_SITE_STG,
    },
  },
  partnersonline_stg: {
    auth: {
      authorizationUrl: AUTHORIZATION_URL_PARTNERS_STG,
      logoutUrl: LOGOUT_URL_PARTNERS_STG,
      clientId: GF_CLIENT_ID_STG,
    },
    gnfapi: {
      apikey: API_KEY_PARTNERS_STG,
    },
    gatewayUrl: API_GATEWAY_URL_STG,
    analyticsApiKey: ANALYTICS_API_KEY_STG,
    help: {
      url: DOCS_SITE_PARTNERS_STG,
    },
  },
  partnersonline_prod: {
    auth: {
      authorizationUrl: AUTHORIZATION_URL_PARTNERS_PROD,
      logoutUrl: LOGOUT_URL_PARTNERS_PROD,
      clientId: GF_CLIENT_ID_PROD,
    },
    gnfapi: {
      apikey: API_KEY_PARTNERS_PROD,
    },
    gatewayUrl: API_GATEWAY_URL_PROD,
    analyticsApiKey: ANALYTICS_API_KEY_PROD,
    help: {
      url: DOCS_SITE_PARTNERS_PROD,
    },
  },
  stg: {
    auth: {
      authorizationUrl: AUTHORIZATION_URL_STG,
      logoutUrl: LOGOUT_URL_STG,
      clientId: GF_CLIENT_ID_STG,
    },
    gnfapi: {
      apikey: API_KEY_STG,
    },
    gatewayUrl: API_GATEWAY_URL_STG,
    analyticsApiKey: ANALYTICS_API_KEY_STG,
    help: {
      url: DOCS_SITE_STG,
    },
  },
  prod: {
    auth: {
      authorizationUrl: AUTHORIZATION_URL_PROD,
      clientId: GF_CLIENT_ID_PROD,
      logoutUrl: LOGOUT_URL_PROD,
    },
    gnfapi: {
      apikey: API_KEY_PROD,
    },
    gatewayUrl: API_GATEWAY_URL_PROD,
    analyticsApiKey: ANALYTICS_API_KEY_PROD,
    help: {
      url: DOCS_SITE_PROD,
    },
  },
}

if (hostname === 'greenfield.target.com' || hostname === 'greenfield.prod.target.com') {
  appEnv = 'prod'
} else if (hostname === 'greenfield.partnersonline.com') {
  appEnv = 'partnersonline_prod'
} else if (hostname === 'greenfield-release.partnersonline.com' || hostname === 'greenfield-dev.partnersonline.com') {
  appEnv = 'partnersonline_stg'
} else if (
  (hostname === 'localhost' && process.env.APP_ENV === 'stage') ||
  hostname === 'greenfield-release.target.com' ||
  hostname === 'shortstacksproxy.prod.target.com'
) {
  // stg and local configs are currently exactly the same
  // only differentiating them in case something changes
  appEnv = 'stg'
} else {
  appEnv = 'local'
}

const config = envConfigs[appEnv]
const apiConfig = merge(commonConfig, config)

export default apiConfig
